<template>
    <Modal animation-name="up" class="user-modal" :is-open="modalStore.SignUp" @modal-closed="handleCloseModal">
        <template #content>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click="handleCloseModal">
                            <span>×</span>
                        </button>
                        <h4 class="modal-title">{{ blok.Title }}</h4>
                    </div>

                    <div class="modal-body">
                        <Alert
                            v-if="typeof formState.responseMsg === 'string'"
                            class="mb-4"
                            :blok="{ component: 'Alert' }"
                            :message="formState.responseMsg"
                            :status="formState.success ? 'success' : 'error'"
                            :icon="formState.success ? 'check-line' : 'close-line'"
                        ></Alert>
                        <form @submit.prevent="submitForm">
                            <div class="form-group">
                                <FormInput
                                    id="firstName"
                                    v-model="formData.firstname"
                                    :blok="{ component: 'FormInput' }"
                                    :placeholder="$t('labels.firstName')"
                                    :label="$t('labels.firstName')"
                                    name="firstName"
                                    type="text"
                                    :error-message="validationErrorMessages.firstname || formResponseError.firstname"
                                    @blur="v$.firstname.$touch"
                                ></FormInput>
                                <FormInput
                                    id="lastName"
                                    v-model="formData.lastname"
                                    :blok="{ component: 'FormInput' }"
                                    :placeholder="$t('labels.lastName')"
                                    :label="$t('labels.lastName')"
                                    name="lastName"
                                    type="text"
                                    :error-message="validationErrorMessages.lastname || formResponseError.lastname"
                                    @blur="v$.lastname.$touch"
                                ></FormInput>
                            </div>
                            <FormInput
                                id="email"
                                v-model="formData.email"
                                :blok="{ component: 'FormInput' }"
                                :placeholder="$t('labels.email2')"
                                :label="$t('labels.email2')"
                                name="email"
                                type="text"
                                :error-message="validationErrorMessages.email || formResponseError.email"
                                @blur="v$.email.$touch"
                            ></FormInput>
                            <FormInput
                                id="password"
                                v-model="formData.password"
                                :blok="{ component: 'FormInput' }"
                                :placeholder="$t('labels.password')"
                                :label="$t('labels.password')"
                                name="password"
                                type="password"
                                :error-message="validationErrorMessages.password || formResponseError.password"
                                @blur="v$.password.$touch"
                            ></FormInput>
                            <div class="link-to-modal-wrapper">
                                <span class="link-to-modal" @click="switchModal('ResetPassword', true)">{{
                                    blok.ForgotPasswordText
                                }}</span>
                            </div>
                            <label class="newsletter">
                                <input
                                    v-model="formData.newsletter"
                                    type="checkbox"
                                    name="newsletter"
                                    data-parsley-multiple="newsletter"
                                />
                                {{ blok.SubscribeText }}
                            </label>
                            <AdvancedBtn
                                v-if="apr"
                                component-type="button"
                                component-variant="primary"
                                button-type="submit"
                                :full-width="true"
                            >
                                {{ blok.ButtonText }}
                            </AdvancedBtn>
                            <button v-else type="submit" class="sign-in-button">
                                {{ blok.ButtonText }}
                            </button>
                            <div class="not-registered-wrapper">
                                <span class="mr-1">{{ blok.HaveAccountText }}</span>
                                <span class="link-to-modal" @click="switchModal('SignIn', true)">
                                    {{ blok.SignInText }}</span
                                >
                            </div>
                        </form>
                        <div class="or">
                            <div>
                                <img class="hidden md:block" :src="blok.OR.filename" :alt="blok.OR.alt" /><span
                                    class="md:hidden"
                                    >OR</span
                                >
                            </div>
                        </div>

                        <div class="bottom-wrapper">
                            <div class="bottom-section">
                                <h4 class="sign-in-with hidden md:block">
                                    {{ blok.SignUpWithText }}
                                </h4>
                            </div>
                            <div class="bottom-section">
                                <ul class="social-buttons">
                                    <li>
                                        <a
                                            v-if="blok.GoogleIcon"
                                            href="#"
                                            data-provider="google"
                                            class="google"
                                            @click.prevent.stop="authWithSocial"
                                        >
                                            <img
                                                class="hidden md:block"
                                                data-provider="google"
                                                :src="blok.GoogleIcon.filename"
                                                :alt="blok.GoogleIcon.alt"
                                                width="30"
                                            />
                                            <span class="social-text md:hidden flex" data-provider="google"
                                                ><img
                                                    data-provider="google"
                                                    :src="blok.GoogleMobileIcon.filename"
                                                    :alt="blok.GoogleMobileIcon.alt"
                                                />
                                                {{ blok.GoogleMobileText }}
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            v-if="blok.FacebookIcon"
                                            href="#"
                                            data-provider="facebook"
                                            class="facebook"
                                            @click.prevent.stop="authWithSocial"
                                        >
                                            <img
                                                class="hidden md:block"
                                                data-provider="facebook"
                                                :src="blok.FacebookIcon.filename"
                                                :alt="blok.FacebookIcon.alt"
                                                width="30"
                                            />
                                            <span class="social-text md:hidden flex" data-provider="facebook">
                                                <img
                                                    data-provider="facebook"
                                                    :src="blok.FacebookMobileIcon.filename"
                                                    :alt="blok.FacebookMobileIcon.alt"
                                                />
                                                {{ blok.FacebookMobileText }}</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from './Modal.vue';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';
import { useModalStore } from '~/../_base/store/modal';
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { ISignUpFormData, ISignUpModalConfig, ISignUpFormState } from '~ui/types/components/modals/SignUpModal';

const config = useRuntimeConfig();

const modalStore: any = useModalStore();

const { $encodeBase64, $decodeBase64 } = useNuxtApp();

const { t } = useI18n();

const props = defineProps<{ blok: ISignUpModalConfig; formState: ISignUpFormState; apr?: boolean }>();

const authUrl = config.public.AUTH_URL;
const returnUrl = $encodeBase64($decodeBase64(config.public.RETURN_URL) + '/customer');

const url = `${authUrl}/callback?return_url=${returnUrl}`;
const formResponseError = ref({
    firstname: null,
    lastname: null,
    email: null,
    password: null,
});
const formData = ref<ISignUpFormData>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    newsletter: true,
    return_url: returnUrl,
});

const eraseFormData = () => {
    formData.value.firstname = '';
    formData.value.lastname = '';
    formData.value.email = '';
    formData.value.password = '';
    formData.value.newsletter = true;

    formResponseError.value.firstname = null;
    formResponseError.value.lastname = null;
    formResponseError.value.email = null;
    formResponseError.value.password = null;
};

const rules = computed(() => {
    return {
        firstname: {
            required: helpers.withMessage(t('forms.firstNameRequired2'), required),
        },
        lastname: {
            required: helpers.withMessage(t('forms.lastNameRequired2'), required),
        },
        email: {
            required: helpers.withMessage(t('forms.emailRequired3'), required),
            email: helpers.withMessage(t('forms.invalidEmail'), email),
        },
        password: {
            required: helpers.withMessage(t('forms.password'), required),
            minLength: helpers.withMessage(t('forms.passwordMinLength'), minLength(8)),
            containsPasswordRequirement: helpers.withMessage(
                () => t('forms.insecurePassword'),
                (value: any) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
            ),
        },
    };
});

const v$ = useVuelidate(rules, formData);

const validationErrorMessages = computed(() => {
    return {
        firstname: v$.value.firstname.$errors.map((e) => e.$message)[0],
        lastname: v$.value.lastname.$errors.map((e) => e.$message)[0],
        email: v$.value.email.$errors.map((e) => e.$message)[0],
        password: v$.value.password.$errors.map((e) => e.$message)[0],
    };
});

const resetModal = () => {
    eraseFormData();
    v$.value.$reset();
    modalStore.toggleModal('SignUp', false);
};

const handleCloseModal = () => {
    v$.value.$reset();
    modalStore.toggleModal('SignUp', false);
};

const socialAuth = {
    mobile: false,
    redirectorDomain: authUrl,
    callbackUrlFromAuthSiteToCurrentSite: url,
};

const switchModal = (modalId: string, state: boolean) => {
    eraseFormData();
    v$.value.$reset();
    modalStore.toggleModal(modalId, state);
};

const socialLoginPopupWindow = (url: any, w: any, h: any) => {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    // @ts-ignore
    return window.open(
        url,
        // @ts-ignore
        config.public.SOCIAL_LOGIN,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left
    );
};
const authWithSocial = (e: any) => {
    e.stopPropagation();
    const provider = e.target.dataset.provider;
    const url = `${socialAuth.redirectorDomain}/${provider}?mobile=${socialAuth.mobile}&return_url=${$encodeBase64($decodeBase64(socialAuth.callbackUrlFromAuthSiteToCurrentSite)) + '/customer'}`;

    socialLoginPopupWindow(url, 500, 600);
};

const emit = defineEmits<{
    (e: 'submit', formData: ISignUpFormData): void;
}>();

const submitForm = () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        emit('submit', formData.value);
    }
};

watch(
    () => props.formState,
    (newFormState) => {
        if (newFormState.success) {
            resetModal();
        }

        if (!newFormState.success && newFormState.responseMsg) {
            formResponseError.value.firstname = newFormState.responseMsg?.firstname?.[0];
            formResponseError.value.lastname = newFormState.responseMsg?.lastname?.[0];
            formResponseError.value.email = newFormState.responseMsg?.email?.[0];
            formResponseError.value.password = newFormState.responseMsg?.password?.[0];
        }
    },
    { deep: true }
);
</script>
<style lang="postcss" scoped>
form {
    .form-group {
        @apply flex gap-6;

        > div {
            @apply w-[50%];
        }
    }

    :deep(.form-input) {
        @apply mb-0;
    }

    .newsletter {
        @apply text-[0.8rem] flex items-center my-6;

        input {
            @apply mr-1;
        }
    }
}
</style>
